// extracted by mini-css-extract-plugin
export var AboutUs = "index-module--AboutUs--3Iv7I";
export var CardGroup = "index-module--CardGroup--ToYAZ";
export var CardItem = "index-module--CardItem--hN6gN";
export var CardItemEmail = "index-module--CardItemEmail--kD4lj";
export var ContactUs = "index-module--ContactUs--27gnu";
export var ContactUsIcon = "index-module--ContactUsIcon--rXQx7";
export var Content = "index-module--Content--8PaAG";
export var Culture = "index-module--Culture--NGHm8";
export var CultureList = "index-module--CultureList--cOuD0";
export var Label = "index-module--Label--4msji";
export var Label_1 = "index-module--Label_1--OkJ3b";
export var Label_2 = "index-module--Label_2--jFAlX";
export var Label_3 = "index-module--Label_3--QUKCz";
export var SwitchPicture = "index-module--SwitchPicture--LSvzx";
export var banner = "index-module--banner--BMAx4";
export var customTitleClass = "index-module--customTitleClass--7CRsE";
export var iconCard = "index-module--iconCard--gHl8b";
export var newEnAboutUsWrapper = "index-module--newEnAboutUsWrapper--Uq546";
export var part2Card = "index-module--part2Card--nN6MT";
export var part2CardItem = "index-module--part2CardItem--+ySjL";
export var part2Title = "index-module--part2Title--Ga0sE";
export var part3 = "index-module--part3--tXqz1";
export var part3Card = "index-module--part3Card---H5HX";
export var part3CardItem = "index-module--part3CardItem--DrWPG";
export var part3CardWrapper = "index-module--part3CardWrapper--3gcrK";
export var part3Title = "index-module--part3Title--Gw8iU";
export var part3TitleText = "index-module--part3TitleText--kGieY";
export var part4 = "index-module--part4--1zIqR";
export var part4Content = "index-module--part4Content--pt7QP";
export var part4Img = "index-module--part4Img--2LyWL";
export var part4Title = "index-module--part4Title--vZh2e";
export var part5 = "index-module--part5--I+KOW";
export var part5Content = "index-module--part5Content--n29y2";
export var part5Title = "index-module--part5Title--0W0P+";
export var part6 = "index-module--part6--fY3bW";
export var part6Content = "index-module--part6Content--i+m3R";
export var part6Img1 = "index-module--part6Img1--0vWCX";
export var part6Img2 = "index-module--part6Img2--tY1R6";
export var part6Title = "index-module--part6Title--uBsmv";
export var rightContent = "index-module--rightContent--sa2Ek";